var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("AppHeader"),
      _c(
        "b-container",
        { attrs: { id: "b-container" } },
        [
          _c("router-view", {
            staticClass: "content",
            on: { "update:activeUser": _vm.updateActiveUser }
          })
        ],
        1
      ),
      _c("AppFooter", { attrs: { user: _vm.activeUser } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }