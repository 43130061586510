var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isUserLoggedIn
      ? _c("div", [
          _c("p", [
            _vm._v("Login to have the combobox automatically selected")
          ]),
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.login } },
            [_vm._v("Login")]
          )
        ])
      : _c("div", [
          _c("p", [
            _vm._v("User " + _vm._s(_vm.activeUser.email) + " is logged in.")
          ]),
          _c("pre", [_vm._v("      " + _vm._s(_vm.activeUser) + "\n    ")]),
          _c(
            "button",
            { staticClass: "btn btn-danger", on: { click: _vm.logout } },
            [_vm._v("Logg out")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }