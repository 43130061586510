import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment-timezone'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    totCounter: 0,
    gCounter: 0,
    maxBookings: 50,
    maxBookingGulating: 15,
    maxBookingGulatingSat: 10,
    todaysDate: moment().tz('Europe/Oslo').format(),
    lastPageReloadTime: new Date()
  },
  mutations: {
    setTodaysDate (state, todaysDate) {
      state.todaysDate = todaysDate
    },
    setTotCounter (state, totCounter) {
      state.totCounter = totCounter
    },
    setGCounter (state, gCounter) {
      state.gCounter = gCounter
    }
  },
  actions: {
    updateTodaysDate ({ commit }, todaysDate) {
      commit('setTodaysDate', todaysDate)
    },
    updateTotCounter ({ commit }, totCounter) {
      commit('setTotCounter', totCounter)
    },
    updateGCounter ({ commit }, gCounter) {
      commit('setGCounter', gCounter)
    }
  },
  getters: {
    getTotCounter: (state) => {
      return state.totCounter
    },
    getGCounter: (state) => {
      return state.gCounter
    },
    getTodaysDate: (state) => {
      return state.todaysDate
    },
    getMaxBookings: (state) => {
      return state.maxBookings
    },
    getMaxBookingGulating: (state) => {
      return state.maxBookingGulating
    },
    getMaxBookingGulatingSat: (state) => {
      return state.maxBookingGulatingSat
    },
    getLastPageReloadTime: (state) => {
      return state.lastPageReloadTime
    }
  }
})
