<template>
  <div class="row">
    <div v-bind:class="[(this.$route.name === 'Booking')? 'col-lg-6 col-xs-12' : 'col-lg-12 col-xs-12']">
      <b-card bg-variant="light" class="shadow" :class="{'anonymized': order.isAnonymous}" header-tag="header">
        <h1 slot="header" class="mb-0" >
          <span v-html="highlight(order.id, searchtext)" />
          <div class="float-right" v-if="this.$route.name !== 'Receipt'">
            <button v-if="isSuperAdmin" class="btn btn-danger mr-1" @click="deleteOrder(order.id)" title="Slett booking"><font-awesome-icon icon="trash-alt"/></button>
            <button v-if="isAdmin" class="btn btn-secondary" @click="anonymizeOrder(order.id)" :disabled="!!order.isAnonymous" title="Anonymiser booking"><font-awesome-icon icon="user-secret" /></button>
          </div>
        </h1>
        <b-card-text>

          <div v-if="this.$route.name === 'Receipt'" class="alert alert-secondary" role="alert">
            <strong style="color:red">Koden <span style="font-size:1.4em">{{order.id}}</span> må skrives tydelig på posene/varene som skal leveres hjem til deg.</strong>
          </div>

          <p v-show='order.isAnonymous'>
            Anonymisert
          </p>
          Status: <strong>{{ translateOrderStatus(order.orderStatus) }}</strong><br />
          Hentested: {{ order.pickupPlaces }}<br/>
          Opprettet: {{ order.dateCreated2 }}<br/>
          Leveringsdato: {{ order.deliveryDate2 }}<br/>
          <span v-if="hasPrice">Pris: kr {{ order.selectedPrice }}</span>
          <span v-else>&nbsp;</span>
          <hr>
          <p><em>Leveres til:</em></p>
          <strong v-html="highlight(order.name, searchtext)"/><br />
          {{order.deliveryAddress.streetName}} {{order.deliveryAddress.streetNumber}} {{order.deliveryAddress.entrance}}<br />
          {{order.deliveryAddress.zipcode}} {{order.deliveryAddress.city}}<br />
          <div v-if="order.deliveryAddress.flatNumber">
            Leilighetsnummer: {{order.deliveryAddress.flatNumber}}
          </div>
          <div v-if="order.deliveryAddress.floor">
            Etasje: {{order.deliveryAddress.floor}}
          </div>
        </b-card-text>

        <b-card-text>
          Tlf.: <span v-html="highlight(order.phoneNumber, searchtext)"/><br/>
          E-post: <span v-html="highlight(order.email, searchtext)"/><br/>
        </b-card-text>
        <div v-if="this.$route.name == 'Bookings'">
          <div class="text-center">
            <router-link :to="{ path: 'booking/' + order.id}"  class="btn btn-secondary">Booking</router-link>
          </div>
        </div>
        <div v-if="this.$route.name == 'Booking'">
          <div class="col-12" text-center>
            <button class='btn btn-secondary btn-block' v-bind:class="{'btn-success': isDelivered}" :disabled='isDelivered || isNotFetched' v-on:click="updateOrderStatus('delivered')">
              Levert
            </button>
            <button class='btn btn-primary btn-block' v-bind:class="{'btn-primary': isFetched}" :disabled='isFetched' v-on:click="updateOrderStatus('fetched')">
              Hentet
            </button>
            <!--
            <button class='btn btn-secondary btn-block' v-bind:class="{'btn-primary': isNotFetched, 'btn-secondary': isFetched}" :disabled='isNotFetched || isDelivered' v-on:click="updateOrderStatus('not fetched')">
              Ikke hentet
            </button>
            -->
          </div>
          <div class="link">
            <router-link :to="{ path: '/bookings'}">Tilbake til alle bookinger</router-link>
          </div>
        </div>
        <div v-if="this.$route.name == 'Receipt'">
          <p>
            Du har ansvaret for at varene er tilfredsstillende pakket for transport. Vi kommer med varene mellom kl. 17:00 - 22:00 i dag, hvis bookingen ble registrert innen fristen (hvis ikke blir det ved første mulige levering).
          </p>
          <div class="col-12 text-center">
            <!--<router-link class="btn btn-secondary" :to="{ path: '/'}">Lukk kvittering</router-link>-->
            <button class="btn btn-secondary" @click="close">Lukk kvittering</button>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-lg-6 col-xs-12" style="margin-top: 32px" v-if="this.$route.name == 'Booking' && order.loggEntries !== null">
      <h1>Logg</h1>
      <ul>
        <li v-for="loggEntry in order.loggEntries" v-bind:key="loggEntry.Date">{{formatDate(loggEntry.Date)}} - {{translate(loggEntry.Action)}} av {{loggEntry.User}}</li>
      </ul>
    </div>
  </div>
</template>
<script>

import orderApi from '@/services/orderApi'

export default {
  props: ['orderitem', 'searchtext', 'user'],
  computed: {
    isAdmin: function () {
      return this.user && this.user.groups && this.user.groups.includes('svipp-admin')
    },
    isSuperAdmin: function () {
      return this.user && this.user.groups && this.user.groups.includes('svipp-admin')
    },
    isDelivered: function () {
      return this.order.orderStatus === 'delivered'
    },
    isFetched: function () {
      return this.order.orderStatus === 'fetched'
    },
    isNotFetched: function () {
      return !this.order.orderStatus || this.order.orderStatus === 'not fetched'
    }
  },
  watch: {
    orderitem: function (newVal, oldVal) {
      this.order = newVal
    },
    user: function (newVal, oldVal) {
      this.activeUser = newVal
    },
    order: function () {
      this.order.deliveryDate2 = this.order.deliveryDate.split('T')[0]
      this.order.dateCreated2 = this.order.dateCreated.split('T')[0]
      this.hasPrice = this.order.selectedPrice != null
    }
  },
  async created () {
    this.order = this.orderitem
    this.order.deliveryDate2 = this.order.deliveryDate.split('T')[0]
    this.order.dateCreated2 = this.order.dateCreated.split('T')[0]
  },
  data () {
    return {
      order: {},
      hasPrice: false,
      activeUser: {}
    }
  },
  methods: {
    close () {
      const home = `${location.protocol}//${location.host}`
      location.replace(home)
    },
    translate (text) {
      if (text === 'created') {
        return 'Opprettet'
      } else if (text === 'fetched') {
        return 'Hentet'
      } else if (text === 'delivered') {
        return 'Levert'
      } else if (text === 'updated_pickuppoint') {
        return 'Hentested oppdatert'
      }
      return text
    },
    formatDate (date) {
      if (date.indexOf('+') > 0) {
        date = date.substring(0, date.indexOf('+'))
      }
      if (date.indexOf('T') > 0) {
        date = date.replace('T', ' ')
      }
      return date
    },
    async updateOrderStatus (orderStatus) {
      this.order.orderStatus = orderStatus
      this.loading = true
      let user = await this.$auth.getUser()
      this.order = await orderApi.updateOrderStatus(this.order, user)
      this.loading = false
      if (orderStatus === 'delivered') {
        let c = this.$store.getters.getTotCounter
        this.$store.dispatch('updateTotCounter', c -= 1)
        if (this.order.pickupPlaces === 'Gulating Ølutsalg (Byhaven)') {
          let g = this.$store.getters.getGCounter
          this.$store.dispatch('updateGCounter', g -= 1)
        }
      }
    },
    async deleteOrder (orderId) {
      if (confirm('Er du sikker på at du vil slette ordren med id ' + orderId)) {
        this.loading = true
        this.$emit('remove-order', orderId)
        await orderApi.deleteOrder(orderId)
        if (this.$route.name !== 'Bookings') {
          this.$router.push('/bookings')
        }
        this.loading = false
      }
    },
    async anonymizeOrder (orderId) {
      if (confirm('Er du sikker på at du vil anonymisere ordren med id ' + orderId)) {
        this.loading = true
        this.order = await orderApi.removePersonalDataForOrder(orderId)
        this.$emit('anonymize-order', this.order)
        this.loading = false
      }
    },
    translateOrderStatus (status) {
      if (status === 'not fetched') {
        return 'Ikke hentet'
      } else if (status === 'fetched') {
        return 'Hentet'
      } else if (status === 'delivered') {
        return 'Levert'
      }
    },
    highlight (words, query) {
      if (query) {
        var regex = new RegExp(query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig')
        var result = words.match(regex)
        if (result) {
          result = result.filter(function (value, index, self) {
            return self.indexOf(value) === index
          })
          result.forEach(match => {
            if (match.length > 0) {
              words = words.split(match).join('\\$1' + match + '\\$2')
            }
          })
          var one = '<bold class=\'highlight\'>'
          var two = '</bold>'
          words = words.split('\\$1').join(one)
          words = words.split('\\$2').join(two)
        }
      }
      return words
    }
  }
}
</script>

<style>
.card-header {
  background-color: var(--svipp-yellow) !important;
}
.link {
  padding-top: 1.6em;
}

.button-block {
  background:magenta;
  /* max-width: 320px; */
}

</style>
