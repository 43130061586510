<template>
  <div>
    <h1>{{ title }}</h1>
    <pulse-loader :loading='loading' :color='color' :size='size' />
    <div>
      <div class="row">
        <div class="col-12">
          <b-form-input v-model="searchtext" placeholder="Søk etter epost/tlf/navn/bookingnr" size="sm" class="my-1"></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-form-select v-model="selectedStatus" :options="status" size="sm" class="my-1"></b-form-select>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-select v-model="selectedTimespan" :options="timespan" v-on:change="refresh" size="sm" class="my-1"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-button variant="outline-primary" size="sm" v-on:click="generateReport">Lag rapport</b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="alert alert-secondary no-booking" v-if="filteredOrders < 1 && !loading">
        <p>Ingen bookinger funnet.</p>
      </div>
    </div>
    <div class="row">
      <div v-for="(order, index) in filteredOrders" :key='index' class="col-lg-4 col-md-6 col-sm-12">
        <OrderDetails @remove-order="removeOrder" @anonymize-order="anonymizeOrder" v-bind:orderitem='order' v-bind:searchtext='searchtext' v-bind:user='user'/>
      </div>
    </div>
  </div>

</template>

<script>
import orderApi from '@/services/orderApi'
import moment from 'moment'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import OrderDetails from './OrderDetails.vue'

export default {
  components: {
    PulseLoader,
    OrderDetails
  },
  data () {
    return {
      color: getComputedStyle(document.documentElement).getPropertyValue('--svipp-yellow'),
      size: getComputedStyle(document.documentElement).getPropertyValue('--svipp-pulseloader-size'),
      title: 'Ikke hentede bookinger siste uke',
      loading: false,
      orders: [],
      checked: false,
      status: [
        { value: 'not fetched', text: 'Ikke hentet' },
        { value: 'fetched', text: 'Hentet' },
        { value: 'delivered', text: 'Levert' },
        { value: 'all', text: 'Alle bookinger' }
      ],
      timespan: [
        { value: 'last_day', text: 'Siste dag' },
        { value: 'last_week', text: 'Siste 7 dager' },
        { value: 'last_month', text: 'Siste måned' },
        { value: 'last_6months', text: 'Siste halvår' },
        { value: 'last_year', text: 'Siste år' }
      ],
      selectedStatus: 'not fetched',
      selectedTimespan: 'last_week',
      searchtext: '',
      previouslySelectedStatus: 'not fetched',
      previouslySelectedTimespan: 'last_week',
      previousSearchtext: '',
      user: {}
    }
  },
  async created () {
    await this.fetchData()
    this.user = await this.getUser()
  },
  computed: {
    // a computed getter
    filteredOrders: function () {
      this.updateTitle()
      let filteredOrders = this.orders
      if (this.selectedStatus !== 'all') {
        filteredOrders = filteredOrders.filter(order => {
          return order.orderStatus === this.selectedStatus
        })
      }
      if (this.searchtext) {
        filteredOrders = filteredOrders.filter(order => {
          return order.name.toLowerCase().includes(this.searchtext.toLowerCase()) || order.email.toLowerCase().includes(this.searchtext.toLowerCase()) || order.phoneNumber.toLowerCase().includes(this.searchtext.toLowerCase()) || order.id.toLowerCase().includes(this.searchtext.toLowerCase())
        })
      }
      filteredOrders = filteredOrders.sort(this.sortByDate)
      return filteredOrders
    }
  },
  watch: {
    loading: function () {
      this.updateTitle()
    },
    searchtext: function (newValue, oldValue) {
      if (newValue) {
        if (newValue.length > 1 && oldValue.length === 0) {
          this.previouslySelectedStatus = this.selectedStatus
          this.previouslySelectedTimespan = this.selectedTimespan
        }
        this.previousSearchtext = oldValue
        this.selectedStatus = 'all'
      } else {
        this.selectedStatus = this.previouslySelectedStatus
        this.selectedTimespan = this.previouslySelectedTimespan
      }
    }
  },
  methods: {
    async getUser () {
      const data = await this.$auth.getUser()
      return data
      // return await this.$auth.getUser()
    },
    removeOrder (orderId) {
      const delOrder = this.orders.filter((o) => o.id === orderId)
      this.orders = this.orders.filter(function (value, index, arr) {
        return value.id !== orderId
      })
      let c = this.$store.getters.getTotCounter
      this.$store.dispatch('updateTotCounter', c -= 1)
      if (delOrder.pickupPlaces === 'Gulating Ølutsalg (Byhaven)') {
        let g = this.$store.getters.getGCounter
        this.$store.dispatch('updateGCounter', g -= 1)
      }
    },
    anonymizeOrder (order) {
      const index = this.orders.findIndex(({ id }) => id === order.id)
      this.orders[index] = order
    },
    async refresh (orderId) {
      let startDate = moment().subtract(7, 'days')
      if (this.selectedTimespan === 'last_day') {
        startDate = moment()
      } else if (this.selectedTimespan === 'last_week') {
        startDate = moment().subtract(7, 'days')
      } else if (this.selectedTimespan === 'last_month') {
        startDate = moment().subtract(1, 'month')
      } else if (this.selectedTimespan === 'last_6months') {
        startDate = moment().subtract(6, 'months')
      } else if (this.selectedTimespan === 'last_year') {
        startDate = moment().subtract(1, 'year')
      }
      this.orders = await orderApi.getOrders(startDate)
    },
    sortByDate (a, b) {
      if (a.dateCreated > b.dateCreated) {
        return -1
      }
      if (a.dateCreated < b.dateCreated) {
        return 1
      }
      return 0
    },
    updateTitle () {
      this.title = ''
      if (this.loading) {
        this.title = 'Henter bookinger'
        return
      }
      if (this.searchtext) {
        this.title = 'Søker etter tekst'
        return
      }
      if (!this.orders || this.orders.length < 1) {
        this.title = 'Oversikt over bookinger'
        return
      }
      if (this.selectedStatus === 'not fetched') {
        this.title = 'Ikke hentede bookinger '
      } else if (this.selectedStatus === 'fetched') {
        this.title = 'Hentede bookinger '
      } else if (this.selectedStatus === 'delivered') {
        this.title = 'Leverte bookinger '
      } else if (this.selectedStatus === 'all') {
        this.title = 'Alle bookinger '
      }
      if (this.selectedTimespan === 'last_day') {
        this.title += 'siste dag'
      } else if (this.selectedTimespan === 'last_week') {
        this.title += 'siste uke'
      } else if (this.selectedTimespan === 'last_month') {
        this.title += 'siste måned'
      } else if (this.selectedTimespan === 'last_6months') {
        this.title += 'siste halvår'
      } else if (this.selectedTimespan === 'last_year') {
        this.title += 'siste år'
      }
    },
    async fetchData () {
      this.error = this.orders = []
      this.loading = true
      let startDate = moment().subtract(7, 'days')
      if (this.selectedTimespan === 'last_day') {
        startDate = moment()
      } else if (this.selectedTimespan === 'last_week') {
        startDate = moment().subtract(7, 'days')
      } else if (this.selectedTimespan === 'last_month') {
        startDate = moment().subtract(1, 'month')
      } else if (this.selectedTimespan === 'last_6months') {
        startDate = moment().subtract(6, 'months')
      } else if (this.selectedTimespan === 'last_year') {
        startDate = moment().subtract(1, 'year')
      }
      this.orders = await orderApi.getOrders(startDate)
      this.loading = false
    },
    generateReport () {
      var file = 'sep=,' + '\n'
      file += this.generateTitleRow()
      for (var i = 0; i < this.filteredOrders.length; i++) {
        file += this.generateOrderRow(this.filteredOrders[i])
      }
      if (file === '') {
        return
      }
      this.downloadFile(file)
    },
    generateTitleRow () {
      let norwegianColumns = ['Bookingnr', 'Opprettet', 'Hentet', 'Levert', 'Levering', 'Navn', 'Telefon', 'Epost', 'Status', 'Gate', 'Gatenr', 'Etasje', 'Ing', 'Lnr', 'Postnr', 'Poststed', 'Butikker', 'Pris']
      let titleRow = ''
      for (var column in norwegianColumns) {
        titleRow += norwegianColumns[column] + ','
      }
      return titleRow + '\r\n'
    },
    generateOrderRow (order) {
      let columns = ['id', 'dateCreated', 'fetched', 'delivered', 'deliveryDate', 'name', 'phoneNumber', 'email', 'orderStatus', 'deliveryAddress', 'pickupPlaces', 'selectedPrice']
      let row = ''
      for (var column in columns) {
        let property = columns[column]
        if (property === 'deliveryAddress') {
          row += '' + this.generateAddressColumn(order[property]) + ','
        } else if (property === 'orderStatus') {
          row += '"' + this.generateOrderStatusColumn(order[property]) + '",'
        } else if (property === 'selectedPrice') {
          let price = order[property] ? order[property] : 0
          row += '"' + price + '",'
        } else if (property === 'dateCreated') {
          let date = order[property]
          row += '"' + this.formatDate(date) + '",'
        } else if (property === 'fetched' || property === 'delivered') {
          let date = this.getLast(order, property)
          row += '"' + this.formatDate(date) + '",'
        } else {
          row += '"' + order[property] + '",'
        }
      }
      return row + '\r\n'
    },
    getLast (order, statusName) {
      if (!order.loggEntries) {
        return 'N/A'
      }
      let index = order.loggEntries.map(loggEntry => loggEntry.Action).lastIndexOf(statusName)
      let date = ''
      if (index > -1) {
        let loggEntry = order.loggEntries[index]
        if (loggEntry !== null) {
          date = loggEntry.Date
        }
      }
      return date
    },
    formatDate (date) {
      if (date.indexOf('+') > 0) {
        date = date.substring(0, date.indexOf('+'))
      }
      if (date.indexOf('T') > 0) {
        date = date.replace('T', ' ')
      }
      return date
    },
    generateAddressColumn (deliveryAddress) {
      let deliveryAddressFields = ['streetName', 'streetNumber', 'floor', 'entrance', 'flatNumber', 'zipcode', 'city']
      let address = ''
      for (var field in deliveryAddressFields) {
        let property = deliveryAddressFields[field]
        if (deliveryAddress[property]) {
          address += '"' + deliveryAddress[property] + '" '
        } else {
          address += '"" '
        }

        if (property !== 'city') {
          address += ','
        }
      }
      return address
    },
    generateOrderStatusColumn (status) {
      if (status === 'not fetched') {
        return 'Ikke hentet'
      } else if (status === 'fetched') {
        return 'Hentet'
      } else if (status === 'delivered') {
        return 'Levert'
      } else {
        return status
      }
    },
    downloadFile (file) {
      var link = document.createElement('a')
      link.href = 'data:text/csv;charset=utf-8,' + escape(file)
      link.style = 'visibility:hidden'
      link.download = 'Svipp_' + moment().format('YYMMDD_HHmmss') + '.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style>
.no-booking {
  margin-top: 2em;
}
</style>
