<template>
  <div>
    <div v-if=!isUserLoggedIn>
      <p>Login to have the combobox automatically selected</p>
      <button class='btn btn-primary' v-on:click="login">Login</button>
    </div>
    <div v-else>
      <p>User {{activeUser.email}} is logged in.</p>
      <pre>
        {{activeUser}}
      </pre>
      <button class='btn btn-danger' v-on:click="logout">Logg out</button>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      activeUser: null,
      isUserLoggedIn: false
    }
  },
  methods: {
    async logout () {
      await this.$auth.logout()
      await this.refreshActiveUser()
      this.$router.push('/login')
    },
    login () {
      this.$auth.loginRedirect('/login')
    },
    async refreshActiveUser () {
      this.activeUser = await this.$auth.getUser()
    }
  },
  watch: {
    activeUser: function () {
      this.isUserLoggedIn = this.activeUser != null
    }
  },
  async created () {
    this.refreshActiveUser()
  }
}
</script>
