import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from '@/components/Login.vue'
/* import AddToExistingBooking from '@/components/AddToExistingBooking'
import AddNewBooking from '@/components/AddNewBooking'
import Terms from '@/components/Terms'
import Faq from '@/components/Faq' */
import Bookings from '@/components/Bookings'
// import Booking from '@/components/Booking'
import _404 from '@/components/404'
// import _403 from '@/components/403'
import Auth from '@okta/okta-vue'

Vue.use(Auth, {
  issuer: 'https://dev-819649.oktapreview.com/oauth2/default',
  client_id: '0oak2uqfqyJ2dIOwI0h7',
  redirect_uri: window.location.origin + '/implicit/callback',
  scope: 'openid profile email'
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/404',
      component: _404
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/Bookings',
      name: 'Bookings',
      component: Bookings,
      meta: {
        requiresAuth: true,
        requiresGroup: 'svipp-bud'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/implicit/callback',
      component: Auth.handleCallback()
    }
    /*
     {
      path: '/403',
      component: _403
    },
    {
      path: '/addnewbooking',
      name: 'AddNewBooking',
      component: AddNewBooking
    },
    {
      path: '/vilkaar',
      name: 'Terms',
      component: Terms
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq
    },
    {
      path: '/addtoexistingbooking',
      name: 'AddToExistingBooking',
      component: AddToExistingBooking
    },
    {
      path: '/receipt/:id',
      name: 'Receipt',
      component: Booking
    },
    {
      path: '/Booking/:id',
      name: 'Booking',
      component: Booking,
      meta: {
        requiresAuth: true,
        requiresGroup: 'svipp-bud'
      }
    } */
  ]
})

router.beforeEach(authRedirectGuard())

function authRedirectGuard () {
  return async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
      Vue.prototype.$auth.loginRedirect(to.path)
    } else {
      if (to.meta.requiresGroup) {
        var user = await Vue.prototype.$auth.getUser()
        if (user && (user.groups.includes(to.meta.requiresGroup) || user.groups.includes('svipp-admin') || user.groups.includes('svipp-superadmin'))) {
          next()
        } else {
          router.push('403')
        }
      } else {
        next()
      }
    }
  }
}
export default router
