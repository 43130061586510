var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "container" } },
    [
      _c("b-jumbotron", { staticClass: "svippotron" }, [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v(
            "\n      Vi har dessverre valgt å legge ned tjenesten SVIPPhjem fra 1.november. "
          ),
          _c("br"),
          _vm._v(
            "\n      Takk til alle dere som har brukt hjemlevering gjennom oss.\n    "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }