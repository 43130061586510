var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("hr", { staticClass: "svipp-hr" }),
    _c("div", { staticClass: "footer-content" }, [
      _c("p", { staticClass: "d-flex justify-content-center" }, [
        _c("strong", [_vm._v("SVIPPhjem")]),
        _vm._v(
          "  levert av helthjem Polaris Distribusjon © " +
            _vm._s(new Date().getFullYear()) +
            "\n    "
        )
      ]),
      _vm.env === "development"
        ? _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticStyle: {
                  border: "2px solid #ff3300",
                  padding: "16px",
                  "border-radius": "8px"
                }
              },
              [
                _vm.isUserLoggedIn
                  ? _c("div", [
                      _vm._v(
                        "\n          LOGGED INN: " +
                          _vm._s(_vm.isUserLoggedIn) +
                          "\n          | USER: " +
                          _vm._s(_vm.activeUser.name) +
                          "\n          | GROUPS: " +
                          _vm._s(_vm.activeUser.groups) +
                          "\n        "
                      )
                    ])
                  : _c("div", [_vm._v("User not logged inn")]),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { to: "/" }
                      },
                      [_vm._v("home")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { to: "/login" }
                      },
                      [_vm._v("login")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { to: "/addnewbooking" }
                      },
                      [_vm._v("new booking")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { to: "/addtoexistingbooking" }
                      },
                      [_vm._v("update booking")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary mr-1",
                        attrs: { to: "/bookings" }
                      },
                      [_vm._v("bookings")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary  mr-1",
                        attrs: { to: "/vilkaar" }
                      },
                      [_vm._v("terms")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary  mr-1",
                        attrs: { to: "/faq" }
                      },
                      [_vm._v("faq")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary  mr-1",
                        attrs: { to: "/403" }
                      },
                      [_vm._v("403")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary  mr-1",
                        attrs: { to: "/404" }
                      },
                      [_vm._v("404")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }