import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import moment from 'moment'

const client = axios.create({
  // baseURL: 'http://localhost:8081'. Values found in .env.development AND .env.production'
  baseURL: process.env.VUE_APP_BASE_URL,
  json: true
})

// before a request is made start the nprogress
client.interceptors.request.use(config => {
  NProgress.start()
  return config
})
// before a response is returned stop nprogress
client.interceptors.response.use(response => {
  NProgress.done()
  return response
})

export default {
  async execute (method, resource, data) {
    let accessToken = await Vue.prototype.$auth.getAccessToken()
    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        // Serverless api-key. Values found in .env.development AND .env.production
        'x-api-key': process.env.VUE_APP_API_KEY
      }
    }).then(req => {
      return req.data
    }).catch(function (error) {
      return error.response.data
    })
  },
  getOrders (startDate, endDate) {
    if (!startDate) startDate = moment().subtract(7, 'days')
    if (!endDate) endDate = moment().add(1, 'day')
    return this.execute('get', `/orders/${startDate.format('YYYY-MM-DD')}/${endDate.format('YYYY-MM-DD')}`)
  },
  getNumberOfOrders () {
    return this.execute('get', '/numberOfOrders')
  },
  createOrder (order, user) {
    var orderUserObject = {
      order: order,
      user: user
    }
    return this.execute('post', '/order', orderUserObject)
  },
  getOrder (id) {
    if (id === undefined || id === null || id.trim().length <= 0) {
      return null
    }
    return this.execute('get', `/order/${id}`)
  },
  updateOrderStatus (order, user) {
    var orderUserObject = {
      order: order,
      user: user
    }
    return this.execute('put', `/updateOrderStatus/${order.id}`, orderUserObject)
  },
  updateOrderPickupPoints (order, user) {
    var orderUserObject = {
      order: order,
      user: user
    }
    return this.execute('put', `/updateOrderPickupPoints/${order.id}`, orderUserObject)
  },
  removePersonalDataForOrder (orderId) {
    return this.execute('put', `/removePersonalDataForOrder/${orderId}`, orderId)
  },
  deleteOrder (orderId) {
    return this.execute('delete', `/order/${orderId}`)
  },
  getShops () {
    return this.execute('get', '/shops')
  },
  getTerms () {
    return this.execute('get', '/terms')
  }
}
