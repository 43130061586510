<template>
  <div id="container">
    <b-jumbotron class="svippotron">
      <h3 class="text-center">
        Vi har dessverre valgt å legge ned tjenesten SVIPPhjem fra 1.november. <br/>
        Takk til alle dere som har brukt hjemlevering gjennom oss.
      </h3>
    </b-jumbotron>
    <!--
      <b-jumbotron class="svippotron">
      <h1 class="text-center">Bestill hjemlevering</h1>
      <h3 class="text-center">Handle i byen - få varene levert hjem på døra</h3>
      <br />
      <br />
      <div class="text-center">
        <p v-if="checkCounter()">
          <router-link to="/addnewbooking" class="btn btn-success btn-lg">Legg til booking <font-awesome-icon icon="angle-right" /></router-link>
        </p>
        <p v-else>
          <span v-html="this.message"></span>
           <br />
          <br />
          <button class="btn btn-success btn-lg" @click="refresh">Oppdater status</button>
        </p>

        <p>
          <router-link to="/addtoexistingbooking" class="jumbotron-link" variant="link">Legge til eksisterende bookinger</router-link>
        </p>

      </div>
    </b-jumbotron>

    <div class="card">
      <div class="card-body">
        <p>
        Hvor trasig er det ikke å ta med seg fulle poser på bussen og på sykkelen hjem, eller i det hele tatt fylle bilen med varer?
        Nå kan du slippe det med den nye tjenesten SVIPPhjem.
        </p>
        <ol>
          <li>Du handler i butikken.</li>
          <li>Du betaler for hjemlevering (innenfor Trondheim Kommune) i kassen.</li>
          <li>Du booker din hjemlevering her på svipphjem.no.</li>
          <li>Du skriver bookingkoden på posene dine.</li>
          <li>Budet henter posene og "SVIPP" så får du dem levert på døra mellom 17:00-22:00.</li>
        </ol>
      </div>
    </div>
    -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      env: process.env.NODE_ENV,
      activeUser: null,
      message: ''
    }
  },
  computed: {
    isUserLoggedIn: function () {
      return this.activeUser != null
    }
  },
  async created () {
    await this.refreshActiveUser()
    this.checkCounter()
  },
  mounted () {
    const lastPageLoadTime = this.$store.getters.getLastPageReloadTime
    if (new Date() - lastPageLoadTime >= 300000) {
      location.reload(true)
    }
  },
  methods: {
    refresh () {
      location.reload(true)
    },
    async logout () {
      await this.$auth.logout()
      await this.refreshActiveUser()
      this.$router.push('/')
    },
    login () {
      this.$auth.loginRedirect()
    },
    async refreshActiveUser () {
      this.activeUser = await this.$auth.getUser()
    },
    checkCounter () {
      if (this.$store.getters.getTotCounter > this.$store.getters.getMaxBookings || this.$store.getters.getTotCounter === this.$store.getters.getMaxBookings) {
        this.message = 'Vi har nå maks antall hjemleveringer med SVIPPhjem i dag. <br/>Ber om at du forsøker å legge inn igjen neste mulige virkedag. <br/> Beklager ulempen dette medfører.'
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style>

.jumbotron.svippotron {
  margin-top: 2em;
  background-color: var(--svipp-yellow);
  color: white;
}

.jumbotron span {
  color: black;
}

.jumbotron h1 {
  color: white;
  font-weight: bolder;
}

.jumbotron h3 {
  color: black;
  font-weight: 200;
}

.jumbotron-link {
  vertical-align: center;
  font-weight: 700;
  padding: 14px;
  color: var(--svipp-white);
  text-decoration: underline;
}

</style>
