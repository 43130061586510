var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "lg", type: "light" } },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          attrs: {
            src: require("../assets/svipphjem-logo.svg"),
            height: "70",
            alt: "Svipp"
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }