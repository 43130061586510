import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import LoadScript from 'vue-plugin-load-script'
import VeeValidate, { Validator } from 'vee-validate'
import nbNo from '../node_modules/vee-validate/dist/locale/nb_NO'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faUserSecret, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleRight)
library.add(faUserSecret)
library.add(faTrashAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(LoadScript)
Vue.use(VeeValidate)
Validator.localize('nb_NO', nbNo)

// local files
// you have to put your scripts into the public folder.
// that way webpack simply copy these files as it is.
Vue.loadScript('https://di.no/addresshelper/diah.js')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
