var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("pulse-loader", {
        attrs: { loading: _vm.loading, color: _vm.color, size: _vm.size }
      }),
      _c("div", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("b-form-input", {
                staticClass: "my-1",
                attrs: {
                  placeholder: "Søk etter epost/tlf/navn/bookingnr",
                  size: "sm"
                },
                model: {
                  value: _vm.searchtext,
                  callback: function($$v) {
                    _vm.searchtext = $$v
                  },
                  expression: "searchtext"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6" },
            [
              _c("b-form-select", {
                staticClass: "my-1",
                attrs: { options: _vm.status, size: "sm" },
                model: {
                  value: _vm.selectedStatus,
                  callback: function($$v) {
                    _vm.selectedStatus = $$v
                  },
                  expression: "selectedStatus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6" },
            [
              _c("b-form-select", {
                staticClass: "my-1",
                attrs: { options: _vm.timespan, size: "sm" },
                on: { change: _vm.refresh },
                model: {
                  value: _vm.selectedTimespan,
                  callback: function($$v) {
                    _vm.selectedTimespan = $$v
                  },
                  expression: "selectedTimespan"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12 col-md-6" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: { click: _vm.generateReport }
                },
                [_vm._v("Lag rapport")]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _vm.filteredOrders < 1 && !_vm.loading
          ? _c("div", { staticClass: "alert alert-secondary no-booking" }, [
              _c("p", [_vm._v("Ingen bookinger funnet.")])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.filteredOrders, function(order, index) {
          return _c(
            "div",
            { key: index, staticClass: "col-lg-4 col-md-6 col-sm-12" },
            [
              _c("OrderDetails", {
                attrs: {
                  orderitem: order,
                  searchtext: _vm.searchtext,
                  user: _vm.user
                },
                on: {
                  "remove-order": _vm.removeOrder,
                  "anonymize-order": _vm.anonymizeOrder
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }