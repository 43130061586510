<template>
  <footer class="footer">
    <hr class="svipp-hr"/>
    <div class="footer-content">
      <p class="d-flex justify-content-center">
        <strong>SVIPPhjem</strong>&nbsp; levert av helthjem Polaris Distribusjon © {{new Date().getFullYear()}}
      </p>
      <!-- <p class="d-flex justify-content-center">
        E-post:&nbsp;<a href="mailto:svipphjem@podi.no?subject=SVIPPhjem">svipphjem@podi.no</a>
      </p> -->
      <div class="d-flex justify-content-center" v-if="env === 'development'">
        <div style="border: 2px solid #ff3300; padding: 16px; border-radius: 8px;">
          <div v-if="isUserLoggedIn">
            LOGGED INN: {{ isUserLoggedIn }}
            | USER: {{ activeUser.name }}
            | GROUPS: {{activeUser.groups}}
          </div>
          <div v-else>User not logged inn</div>
          <div>
            <router-link to="/" class="btn btn-sm btn-outline-secondary mr-1">home</router-link>
            <router-link to="/login" class="btn btn-sm btn-outline-secondary mr-1">login</router-link>
            <router-link to="/addnewbooking" class="btn btn-sm btn-outline-secondary mr-1">new booking</router-link>
            <router-link to="/addtoexistingbooking" class="btn btn-sm btn-outline-secondary mr-1">update booking</router-link>
            <router-link to="/bookings" class="btn btn-sm btn-outline-secondary mr-1">bookings</router-link>
            <router-link to="/vilkaar" class="btn btn-sm btn-outline-secondary  mr-1">terms</router-link>
            <router-link to="/faq" class="btn btn-sm btn-outline-secondary  mr-1">faq</router-link>
            <router-link to="/403" class="btn btn-sm btn-outline-secondary  mr-1">403</router-link>
            <router-link to="/404" class="btn btn-sm btn-outline-secondary  mr-1">404</router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      env: process.env.NODE_ENV,
      isUserLoggedIn: false,
      activeUser: {}
    }
  },
  props: {
    user: {
      type: Object
    }
  },
  methods: {
    async refreshActiveUser () {
      this.activeUser = await this.$auth.getUser()
    }
  },
  watch: {
    user: function () {
      this.activeUser = this.user
    },
    activeUser: function () {
      this.isUserLoggedIn = this.activeUser != null
    }
  },
  async created () {
    this.refreshActiveUser()
  }
}
</script>
