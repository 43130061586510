var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", [_vm._v("404 Page not found!")]),
      _c("p", [
        _vm._v(
          "\n    Oh dear, it seams that you are trying to view a page that does not exist!\n  "
        )
      ]),
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: require("../assets/404.jpg"), alt: "404" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }