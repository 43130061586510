var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        class: [
          this.$route.name === "Booking"
            ? "col-lg-6 col-xs-12"
            : "col-lg-12 col-xs-12"
        ]
      },
      [
        _c(
          "b-card",
          {
            staticClass: "shadow",
            class: { anonymized: _vm.order.isAnonymous },
            attrs: { "bg-variant": "light", "header-tag": "header" }
          },
          [
            _c(
              "h1",
              {
                staticClass: "mb-0",
                attrs: { slot: "header" },
                slot: "header"
              },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.highlight(_vm.order.id, _vm.searchtext)
                    )
                  }
                }),
                this.$route.name !== "Receipt"
                  ? _c("div", { staticClass: "float-right" }, [
                      _vm.isSuperAdmin
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mr-1",
                              attrs: { title: "Slett booking" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteOrder(_vm.order.id)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: {
                                disabled: !!_vm.order.isAnonymous,
                                title: "Anonymiser booking"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.anonymizeOrder(_vm.order.id)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-secret" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            ),
            _c("b-card-text", [
              this.$route.name === "Receipt"
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-secondary",
                      attrs: { role: "alert" }
                    },
                    [
                      _c("strong", { staticStyle: { color: "red" } }, [
                        _vm._v("Koden "),
                        _c("span", { staticStyle: { "font-size": "1.4em" } }, [
                          _vm._v(_vm._s(_vm.order.id))
                        ]),
                        _vm._v(
                          " må skrives tydelig på posene/varene som skal leveres hjem til deg."
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order.isAnonymous,
                      expression: "order.isAnonymous"
                    }
                  ]
                },
                [_vm._v("\n          Anonymisert\n        ")]
              ),
              _vm._v("\n        Status: "),
              _c("strong", [
                _vm._v(_vm._s(_vm.translateOrderStatus(_vm.order.orderStatus)))
              ]),
              _c("br"),
              _vm._v("\n        Hentested: " + _vm._s(_vm.order.pickupPlaces)),
              _c("br"),
              _vm._v("\n        Opprettet: " + _vm._s(_vm.order.dateCreated2)),
              _c("br"),
              _vm._v(
                "\n        Leveringsdato: " + _vm._s(_vm.order.deliveryDate2)
              ),
              _c("br"),
              _vm.hasPrice
                ? _c("span", [
                    _vm._v("Pris: kr " + _vm._s(_vm.order.selectedPrice))
                  ])
                : _c("span", [_vm._v(" ")]),
              _c("hr"),
              _c("p", [_c("em", [_vm._v("Leveres til:")])]),
              _c("strong", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.highlight(_vm.order.name, _vm.searchtext)
                  )
                }
              }),
              _c("br"),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.order.deliveryAddress.streetName) +
                  " " +
                  _vm._s(_vm.order.deliveryAddress.streetNumber) +
                  " " +
                  _vm._s(_vm.order.deliveryAddress.entrance)
              ),
              _c("br"),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.order.deliveryAddress.zipcode) +
                  " " +
                  _vm._s(_vm.order.deliveryAddress.city)
              ),
              _c("br"),
              _vm.order.deliveryAddress.flatNumber
                ? _c("div", [
                    _vm._v(
                      "\n          Leilighetsnummer: " +
                        _vm._s(_vm.order.deliveryAddress.flatNumber) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm.order.deliveryAddress.floor
                ? _c("div", [
                    _vm._v(
                      "\n          Etasje: " +
                        _vm._s(_vm.order.deliveryAddress.floor) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _c("b-card-text", [
              _vm._v("\n        Tlf.: "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.highlight(_vm.order.phoneNumber, _vm.searchtext)
                  )
                }
              }),
              _c("br"),
              _vm._v("\n        E-post: "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.highlight(_vm.order.email, _vm.searchtext)
                  )
                }
              }),
              _c("br")
            ]),
            this.$route.name == "Bookings"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { to: { path: "booking/" + _vm.order.id } }
                        },
                        [_vm._v("Booking")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            this.$route.name == "Booking"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "col-12", attrs: { "text-center": "" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-block",
                          class: { "btn-success": _vm.isDelivered },
                          attrs: {
                            disabled: _vm.isDelivered || _vm.isNotFetched
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateOrderStatus("delivered")
                            }
                          }
                        },
                        [_vm._v("\n            Levert\n          ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-block",
                          class: { "btn-primary": _vm.isFetched },
                          attrs: { disabled: _vm.isFetched },
                          on: {
                            click: function($event) {
                              return _vm.updateOrderStatus("fetched")
                            }
                          }
                        },
                        [_vm._v("\n            Hentet\n          ")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "link" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { path: "/bookings" } } },
                        [_vm._v("Tilbake til alle bookinger")]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            this.$route.name == "Receipt"
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      "\n          Du har ansvaret for at varene er tilfredsstillende pakket for transport. Vi kommer med varene mellom kl. 17:00 - 22:00 i dag, hvis bookingen ble registrert innen fristen (hvis ikke blir det ved første mulige levering).\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-12 text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        on: { click: _vm.close }
                      },
                      [_vm._v("Lukk kvittering")]
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    this.$route.name == "Booking" && _vm.order.loggEntries !== null
      ? _c(
          "div",
          {
            staticClass: "col-lg-6 col-xs-12",
            staticStyle: { "margin-top": "32px" }
          },
          [
            _c("h1", [_vm._v("Logg")]),
            _c(
              "ul",
              _vm._l(_vm.order.loggEntries, function(loggEntry) {
                return _c("li", { key: loggEntry.Date }, [
                  _vm._v(
                    _vm._s(_vm.formatDate(loggEntry.Date)) +
                      " - " +
                      _vm._s(_vm.translate(loggEntry.Action)) +
                      " av " +
                      _vm._s(loggEntry.User)
                  )
                ])
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }