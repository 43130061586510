<template>
  <div id="app">
    <AppHeader/>
    <!-- <Home/> -->
    <b-container id="b-container">
      <router-view class="content" @update:activeUser="updateActiveUser"/>
    </b-container>
    <AppFooter :user='activeUser'/>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import orderApi from '@/services/orderApi'
// import Home from './views/Home.vue'
export default {
  name: 'app',
  async created () {
    document.title = process.env.VUE_APP_TITLE
    await this.fetchData()
  },
  data () {
    return {
      activeUser: {}
    }
  },
  methods: {
    updateActiveUser (user) {
      this.activeUser = user
    },
    async fetchData () {
      const numberOfOrders = await orderApi.getNumberOfOrders()
      if (numberOfOrders) {
        this.$store.dispatch('updateTotCounter', numberOfOrders.totalCount)
        this.$store.dispatch('updateGCounter', numberOfOrders.gulatingCount)
      }
    }
  },
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
:root {
    --svipp-yellow:#FFC128;
    --svipp-white:rgb(231, 226, 211);
    --svipp-grey:#414042;
    --svipp-grey-anonymized:#cccccc;
    --svipp-green:#00B67B;
    --svipp-pulseloader-size: 1.5em
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
}

* {
  font-family: 'Raleway', sans-serif;
}

.content {
  margin-top: 1.2em;
}

.footer-content {
  /* background-color: #00B67B; */
  padding: 12px;
}

/* .faqlink {
  height: 60px;
  margin-right: 3em;
  display: table-cell;
  vertical-align: middle;
  font-weight: 800;
  text-decoration: underline;
  color:black;
}

.box {
 background-color: blueviolet;
 text-align: center;
 vertical-align: bottom;
}

.logo {
  margin-left: 3em;
}
*/

hr.svipp-hr {
  width: 80%;
}

.card {
  margin-top: 20px;
  margin-bottom: 8px;
}
.anonymized header {
  background-color: var(--svipp-grey-anonymized) !important;
}
.highlight {
  font-weight: bold;
  text-decoration: underline;
}

</style>
